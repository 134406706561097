import "../App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useWindowSize } from "../lib/hooks/useWindowSize";
import { Loading } from "./Loading";
import { CustomerConfirmationContent } from "../data/CustomerConfirmationContent";

Modal.setAppElement("#root");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("jp-JP"); // ここでUIの言語が変更できる
ZoomMtg.i18n.reload("jp-JP"); // ここでUIの言語が変更できる

function ZoomMeeting(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonColor, setButtonColor] = useState("#2d8cff");
  const [modalButtonColor, setModalButtonColor] = useState("#2d8cff");
  const [passwordNameError, setPasswordError] = useState("");
  const { width: responsiveWidth, height: responsiveHeight } = useWindowSize();
  const largeModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "590px" : `${responsiveHeight}px`,
      width: "1000px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const middleModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "690px" : `${responsiveHeight}px`,
      width: "700px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const mobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 390 ? "630px" : `${responsiveHeight}px`,
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };
  // iPhone8用の、モーダルスタイル
  const smallMobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: "500px",
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const [passWord, setPassWord] = useState("");
  const [modalIsOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    // ボタン押下時の色を変えてすぐに元に戻す
    setModalButtonColor("#91c2ff");
    setTimeout(() => {
      setModalButtonColor("#2d8cff");
    }, 190);
    // モーダルを閉じる
    setIsOpen(false);
  };

  console.log(window.location.origin + "/j/");
  const meetingNumber = props.match.params.meeting_number;
  const encodedUserName = props.match.params.user_name
    .replace(/-/g, "+")
    .replace(/_/g, "/");
  const decodedUserName = decodeURIComponent(escape(atob(encodedUserName)));
  let diplayName;
  if (decodedUserName.includes('"') || decodedUserName.includes("'")) {
    diplayName = decodedUserName.replace(/["']/g, "");
  } else {
    diplayName = decodedUserName;
  }
  var signatureEndpoint = process.env.REACT_APP_ZOOM_SDK_SIGNATURE_ENDPOINT;
  //var signatureEndpoint = "http://localhost:4000"; // ローカル
  var role = 0; // host開催が1 でクライアント開催が0
  var leaveUrl =
    window.location.origin + "/j/thx/" + props.match.params.user_name;
  var registrantToken = "";

  function getSignature(e) {
    e.preventDefault();
    // ボタン押下時の色を変えてすぐに元に戻す
    setButtonColor("#91c2ff");
    setTimeout(() => {
      setButtonColor("#2d8cff");
    }, 190);
    if (passWord.trim() === "") {
      setPasswordError("パスワードの入力は必須です。");
      return;
    } else {
      setPasswordError("");
    }
    setIsLoading(true);
    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //APIエラーレスポンスの判定およびメッセージの保存
        if (response.id) {
          setErrorMessage(response.id + " : " + response.message);
          setIsLoading(false);
          return;
        }
        const arrayedSignatureData = response.signature.split(".");
        const payload = Buffer.from(arrayedSignatureData[1], "base64").toString(
          "ascii"
        );
        const decodedPayload = JSON.parse(payload);
        setIsLoading(false);
        startMeeting(response.signature, decodedPayload.sdkKey);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.toString());
        console.error(error);
      });
  }

  function startMeeting(signature, sdkKey) {
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl,
      meetingInfo: ["topic", "enctype"],
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber,
          userName: diplayName,
          passWord,
          sdkKey,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.warn(error, "無効なパラメータ");
            // props.history.goBack();
            // TODO: ERROR HANDRING どのパラメータで入れないかレスポンスがあるので参考にハンドリング
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  useEffect(() => {
    const { history, location } = window;
    history.pushState(null, null, location.href);
    const handlePopstate = () => {
      alert("この操作は禁止されています。");
      history.pushState(null, null, location.href);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-restricted-globals
  }, [location.href]);

  const responsiveModalStyles = () => {
    if (responsiveWidth > 1000) {
      return largeModalStyles;
    }
    if (responsiveWidth > 730) {
      return middleModalStyles;
    }
    if (responsiveHeight > 580) {
      return mobileModalStyles;
    }
    if (responsiveHeight < 580) {
      return smallMobileModalStyles;
    }
    return largeModalStyles;
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* 例外発生時のモーダル */}
      {errorMessage !== "" ? (
        <div className="alert">
          <p> {errorMessage}</p>
        </div>
      ) : null}

      <div style={{ width: "660px" }}>
        <Modal isOpen={modalIsOpen} style={responsiveModalStyles()}>
          <div
            style={{
              backgroundColor: "#0070c0",
              color: "#ffffff",
              height: "50px",
              width: "100%",
              textAlign: "center",
              padding: "12px 0 0 0",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                margin: responsiveWidth < 735 ? "2px 0 0 0" : "0 0 0 0",
                fontSize: responsiveWidth < 735 ? "19.5px" : "24px",
              }}
            >
              オンライン窓口ご利用に伴うご案内
            </h1>
          </div>
          <div
            style={{
              height: "50px",
              width: "100%",
              textAlign: "center",
              padding: "12px 0 0 0",
            }}
          >
            <h2
              style={{
                color: "#0070c0",
                margin: "0 0 0 0",
                fontWeight: "bold",
                fontSize: responsiveWidth < 730 ? "16px" : "18px",
              }}
            >
              下記内容のご承諾をお願いいたします。
            </h2>
          </div>
          <div
            style={{
              marginTop: "-25px",
              padding:
                responsiveWidth < 730
                  ? "20px 20px 20px 20px"
                  : "20px 40px 45px 40px",
            }}
          >
            <CustomerConfirmationContent />
          </div>
          <div
            style={{
              textAlign: "center",
              margin: responsiveWidth > 730 ? "-26px 0 0  0" : "-12px 0 0 0",
            }}
          >
            <button
              className="modal-button"
              onClick={() => closeModal()}
              onKeyDown={closeModal}
              tabIndex={0}
              style={{
                textAlign: "center",
                cursor: "pointer",
                width: responsiveWidth > 730 ? "150px" : "120px",
                height: responsiveWidth > 730 ? "35px" : "30px",
                borderRadius: "5px",
                color: "#ffffff",
                backgroundColor: modalButtonColor,
              }}
            >
              OK
            </button>
          </div>
        </Modal>
      </div>
      {/* 一部モバイル端末で入力決定ボタンがポップアップキーボードに重なってしまうため、モバイル時のみ入力欄領域を上に上げる */}
      <main style={{ marginTop: responsiveWidth < 600 ? "-35px" : "0px" }}>
        <h1
          style={{
            fontWeight: "bold",
            fontSize: responsiveWidth < 600 ? "17px" : "19px",
            marginTop: responsiveWidth < 600 ? "150px" : "190px",
          }}
        >
          本オンライン窓口のご案内に記載のある、パスワードをご入力ください
        </h1>
        <form>
          <p
            style={{
              marginTop: "40px",
            }}
          >
            パスワード
          </p>
          <input
            defaultValue=""
            onChange={(event) => setPassWord(event.target.value)}
            type="password"
          ></input>
          <br />
          {passwordNameError && (
            <div
              style={{ color: "tomato", marginTop: "2px", fontWeight: "bold" }}
            >
              {passwordNameError}
            </div>
          )}
          <button
            style={{ backgroundColor: buttonColor }}
            onClick={getSignature}
          >
            確認
          </button>
        </form>
      </main>
    </>
  );
}

export default ZoomMeeting;
